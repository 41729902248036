const menu = [
  {
    id: 1,
    title: 'Pancakes',
    category: 'Πρωινό',
    price: 8.00,
    img: './images/item-1.jpeg',
    desc: `Αφράτα pancakes με σιρόπι σφενδάμου και φρέσκα φρούτα εποχής.`,
  },
  {
    id: 2,
    title: 'diner double',
    category: 'Καφές',
    price: 13.99,
    img: './images/item-2.jpeg',
    desc: `vaporware iPhone mumblecore selvage raw denim slow-carb leggings gochujang helvetica man braid jianbing. Marfa thundercats `,
  },
  {
    id: 3,
    title: 'godzilla milkshake',
    category: 'Γλυκά',
    price: 6.99,
    img: './images/item-3.jpeg',
    desc: `ombucha chillwave fanny pack 3 wolf moon street art photo booth before they sold out organic viral.`,
  },
  {
    id: 4,
    title: 'Αυγά τηγανητά',
    category: 'Πρωινό',
    price: 10.00,
    img: './images/item-4.jpeg',
    desc: `Φρέσκα αυγά ημέρας συνοδευόμενα με πατάτες ή σαλάτα.`,
  },
  {
    id: 5,
    title: 'egg attack',
    category: 'Καφές',
    price: 22.99,
    img: './images/item-5.jpeg',
    desc: `franzen vegan pabst bicycle rights kickstarter pinterest meditation farm-to-table 90's pop-up `,
  },
  {
    id: 6,
    title: 'oreo dream',
    category: 'Γλυκά',
    price: 18.99,
    img: './images/item-6.jpeg',
    desc: `Portland chicharrones ethical edison bulb, palo santo craft beer chia heirloom iPhone everyday`,
  },
  {
    id: 7,
    title: 'Ομελέτα τορτίγια',
    category: 'Πρωινό',
    price: 7.00,
    img: './images/item-7.jpeg',
    desc: `3 αυγά ομελέτα σε στυλ τορτίγια wrap.`,
  },
  {
    id: 8,
    title: 'american classic',
    category: 'Καφές',
    price: 12.99,
    img: './images/item-8.jpeg',
    desc: `on it tumblr kickstarter thundercats migas everyday carry squid palo santo leggings. Food truck truffaut  `,
  },
  {
    id: 9,
    title: 'quarantine buddy',
    category: 'Γλυκά',
    price: 16.99,
    img: './images/item-9.jpeg',
    desc: `skateboard fam synth authentic semiotics. Live-edge lyft af, edison bulb yuccie crucifix microdosing.`,
  },
];
export default menu;
