import React, { useState } from "react";
import { Link } from 'react-router-dom'; 
import Menu from "./Menu";
import Categories from "./Categories";
import items from "./data";
import logo from "./portapolis.png";
import cube_logo from "./cube-logo.png"

const allCategories = [...new Set(items.map((item) => item.category))];

const App = () => {
  const [menuItems, setMenuItems] = useState(items);
  const [activeCategory, setActiveCategory] = useState("");
  const [categories, setCategories] = useState(allCategories);

  const filterItems = (category) => {
    setActiveCategory(category);
    /*
    if (category === "all") {
      setMenuItems(items);
      return;
    }
      */
    const newItems = items.filter((item) => item.category === category);
    setMenuItems(newItems);
  };
  return (
    <main>
      <section className="menu section">
        <div className="title">
          <img src={logo} alt="logo" className="logo" />
          <h2>Μενού</h2>
          <div className="underline"></div> 
        </div>
        <Categories
          categories={categories}
          activeCategory={activeCategory}
          filterItems={filterItems}
        />
        <Menu items={menuItems} />
        <div className="underline_end"></div> 
        <div className="copyrights">
        <a href="https://www.cube-creations.com" target="_blank" rel="noreferrer">
              <img src={cube_logo} alt="cube-logo" className="cube-logo"/>
        </a>
              <h5>© 2024 Cube Creations. All rights reserved.</h5>
        </div>
      </section>
    </main>
  );
};

export default App;